/* eslint-disable eol-last */
const operationRouter = {
  route: null,
  name: null,
  title: '啵哔运营管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  isElementIcon: false,
  filePath: 'view/operation/', // 文件路径
  order: null,
  inNav: true,
  permission: ['超级管理员独有权限'],
  appId: "com.freecomic.bobi.comic",
  children: [
    {
      title: 'APP运营',
      type: 'view',
      name: 'ComicCreate',
      route: '/operation/app-list',
      filePath: 'view/operation/app-list.vue',
      inNav: true,
      permission: ['超级管理员独有权限'],
      icon: 'iconfont icon-add',
      isElementIcon: false,
      appId: "com.freecomic.bobi.comic",
    },
    {
      title: '首页推荐模块',
      type: 'view',
      name: 'RecommendList',
      route: '/operation/recommend-list',
      filePath: 'view/operation/recommend-list.vue',
      inNav: true,
      permission: ['超级管理员独有权限'],
      icon: 'iconfont icon-add',
      isElementIcon: false,
      appId: "com.freecomic.bobi.comic",
    },
    {
      title: '相关推荐',
      type: 'view',
      name: 'RecommendRelative',
      route: '/operation/recommend-relative',
      filePath: 'view/operation/recommend-relative.vue',
      inNav: false,
      permission: ['超级管理员独有权限'],
      icon: 'iconfont icon-add',
      isElementIcon: false,
      appId: "com.freecomic.bobi.comic",
    },
    {
      title: '评论管理',
      type: 'view',
      name: 'CommentList',
      route: '/operation/comment-list',
      filePath: 'view/operation/comment-list.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      isElementIcon: false,
      appId: "com.freecomic.bobi.comic",
    },
  ],
}

export default operationRouter
